import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {graphql, Link} from 'gatsby';
import uuid from 'react-uuid';
import {isDesktop, isMobileOnly, isTablet} from "react-device-detect";
import {GatsbyImage} from "gatsby-plugin-image";
import getHtmlFromRedactorField from '../utils/getHtmlFromRedactorField';
import showMe from '../animations/showMe';
import Seo from '../components/Seo/Seo';

const StyledWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.black};
	display: flex;
	flex-direction: column;
  	height: 100%;
  	justify-content: flex-start;
	overflow-y: scroll;
	position: fixed;
  	top: 0;
  	width: 100%;
  	z-index: 10;
  	@media(min-width: 1024px) {
	  overflow: hidden;
    }
`;

const StyledCloseButton = styled(Link)`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.red};
	border-radius: 50%;
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	display: flex;
	font-size: 14px;
	height: 60px;
	justify-content: center;
	position: fixed;
	right: 20px;
	text-decoration: none;
	top: 20px;
	width: 60px;
	&:active,
	:focus,
	:visited {
		color: ${({ theme }) => theme.colors.white};
	}
`;

const StyledInfoWrapper = styled.div`
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.book};
	opacity: 0;
	position: relative;
	width: 100%;
  	
  @media(min-width: 1024px) {
    left: 26px;
    opacity: 0;
    position: absolute;
    width: 327px;
    bottom: 100px;
  }
`;

const StyledInfoAuthors = styled.div`
	background-color: ${({ theme }) => theme.colors.darkerBlue};
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding: 24px 18px;
	row-gap: 10px;
	width: 100%;

	div {
		&:nth-child(odd) {
			color: ${({ theme }) => theme.colors.sand};
		}
	}
`;

const StyledInfoDescription = styled.div`
	background-color: ${({ theme }) => theme.colors.red};
	color: ${({ theme }) => theme.colors.darkerBlue};
	height: 100%;
	padding: 25px 20px;
	width: 100%;
`;

const StyledBackground = styled.div`
	//height: 800px;
	//left: 50%;
	//top: calc(50% + 100px);
	//transform: translate(-50%, -50%);
  //width: 1400px;
  	background-image: url('${({ background }) => (background || '')};');
  	background-position: center; 
	background-repeat: no-repeat;
	background-size: cover;
	display: none;
	opacity: 0;
	padding-top: 56.25%;
	position: relative;
  	top: 0;
  	width: 100%;
  @media(min-width: 1024px) {
    display: block;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: calc(50% + 100px);
    transform: translate(-50%, -50%);
    padding-top: 0;
    height: 80vh;
    background-size: contain;
  }
`;

const StyledGatsbyImageWrapper = styled.div`
	display: block;
	position: relative;
	width: 100%;
	@media(min-width: 1024px) {
		display: none;
	}
`

const projectTemplate = ({ data: {datoCmsSite}, pageContext }) => {
	const [isMobileOnlyDevice, setIsMobileDevice] = useState(true);
	const [isTabletDevice, setIsTabletDevice] = useState(true);
	const [isDesktopDevice, setIsDesktopDevice] = useState(true);
	useEffect(() => {
		const showContent = document.querySelectorAll('.show-me');
		showMe(showContent[0], { autoAlpha: 1, delay: 0.1, top: isDesktopDevice || isTabletDevice? '50%' : 0, duration: 0.85, ease: 'power2.out' });
		showMe(showContent[1], { autoAlpha: 1, delay: 0.3, bottom: isDesktopDevice ?  20 : 'unset', duration: 0.85, ease: 'power2.out' });
	});
	useEffect(() => {
		setIsMobileDevice(isMobileOnly);
		setIsTabletDevice(isTablet);
		setIsDesktopDevice(isDesktop);
	}, []);

	const lang = pageContext?.locale || '';
	const authors = pageContext?.projectData?.authors || {};
	const background = pageContext?.projectData?.background || '';
	const backgroundMobile = pageContext?.projectData?.backgroundMobile || '';
	const backgroundTablet = pageContext?.projectData?.backgroundTablet || '';
	const backgroundGI = pageContext?.projectData?.backgroundGI || '';
	const backgroundMobileGI = pageContext?.projectData?.backgroundMobileGI || '';
	const backgroundTabletGI = pageContext?.projectData?.backgroundTabletGI || '';
	const description = pageContext?.projectData?.description || '';
	const seoSettings = pageContext?.projectData?.seoSettings || {};
	const backgroundSource = isMobileOnlyDevice ? backgroundMobileGI : isTabletDevice ? backgroundTabletGI : isDesktopDevice ? backgroundGI : backgroundGI;
	const seoGlobal = datoCmsSite?.globalSeo || null;
	return (
		<>
			sus
			 <Seo seoData={seoSettings} seoGlobal={seoGlobal} />
			<StyledWrapper>
				  <StyledBackground
					background={background}
					className="show-me" />
				<StyledGatsbyImageWrapper>
					<GatsbyImage alt="mobile" image={backgroundSource}/>
				</StyledGatsbyImageWrapper>
				<StyledInfoWrapper className="show-me">
					<StyledInfoAuthors>
						{authors &&
							authors.map((author) => {
								const job = author.authorJob || '';
								const name = author.authorName || '';
								return (
									<>
										<div key={uuid()}>{job}</div>
										<div key={uuid()}>{name}</div>
									</>
								);
							})}
					</StyledInfoAuthors>
					{description && <StyledInfoDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />}
				</StyledInfoWrapper>
				<StyledCloseButton to={lang === 'pl' ? '/#projects' : '/en#projects'}>X</StyledCloseButton>
			</StyledWrapper>
		</>
	);
};
export default projectTemplate;
export const query = graphql`
query {
	datoCmsSite {
		globalSeo {
			facebookPageUrl
			fallbackSeo {
				image {
					url
				}
				title
				twitterCard
				description
			}
			siteName
			titleSuffix
			twitterAccount
		}
	}
}
`;
